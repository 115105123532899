import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../store/slice/authSlice";

export const useServiceWorker = (swPath = '') => {
    const [errorCode, setErrorCode] = useState('');
    const [sWorker, setSWorker] = useState();
    const dispatch = useDispatch();


    function verificarSesion (){
        const sessionManaged = JSON.parse(localStorage.getItem("session"));
        if(sessionManaged === undefined || sessionManaged === null){
            dispatch( logout() );
        }

        const dateExpires = new Date(sessionManaged.expires);
        const ahora = new Date();
        const diferencia =  dateExpires - ahora;
        const enMinutos = (diferencia / (1000 * 60));
        if(enMinutos < 2){
            const requestOptions = {
                'method': 'POST',
                headers : {
                    'Authorization': `Bearer ${ sessionManaged.refreshToken }`
                }
            }
            fetch(`${process.env.REACT_APP_API_CORE}panel/refreshtoken`,requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        dispatch( logout() );
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response)=>{
                    sessionManaged.expires = response.response.expires;
                    localStorage.setItem("cToken", response.response.token);
                    localStorage.setItem("session", JSON.stringify(sessionManaged));
                }).catch(error =>{
            })
        }
    }

    useEffect(()=>{
        const checkToken =setInterval(()=>{
                verificarSesion();
        }, 60000);

        return () => clearInterval(checkToken);


    },[])


    return {
        errorCode,
        sWorker
    }

}