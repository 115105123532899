import * as React from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import {useForm} from "../../../customHooks/useForm";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actualizarCuenta, crearCuenta, mostrarCuenta} from "../../../store/thunk/cuentas";
import {openModal, setAlertMessage} from "../../../store/slice/alertSlice";
import {v4} from "uuid";
import {cuentaCancelEdit} from "../../../store/slice/cuentasSlice";

const formValidation = {
    licencia: [(value)=> value.trim() !== "","Licencia es un campo requerido."],
    rfc: [(value)=> {
        const regex = /^([A-Z&Ññ]{3,4})(\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([A-Z\d]{2})?$/i;
        return !regex.test(value);
    },"RFC es un campo requerido."],
    tiempoEspera: [(value)=> {
        const regex = /^[1-9]\d{0,5}$/;
        return regex.test(value);
    },"Por favor coloque el tiempo que esperara antes de iniciar la consulta de estado de la transacción."],
    /*
    rs: [(value)=> value.trim() !== "","Razón social es un campo requerido."],
    direccion: [(value)=> value.trim() !== "","Dirección es un campo requerido."],
    nombreComercial: [(value)=> value.trim() !== "","Nombre comercial es un campo requerido."],
    nombreEnlace:[(value)=>{
        if(value.trim() !== '' ){
            const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
            return regex.test(value);
        }
        return true;
    },"Nombre de enlace inválido"],
    apellidosEnlace:[(value)=>{
        if(value.trim() !== '' ){
            const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
            return regex.test(value);
        }
        return true;
    },"Apellidos del enlace inválido"],
    telefono:[(value)=>{
        const regex = /^[1-9]\d{9}$/;
        return regex.test(value);
    },"Teléfono inválido"],
    */
}

function Detalle (props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { uuid, currentRecord:initialForm } = useSelector(state => state.cuentas);
    const frmResult = useForm( initialForm, formValidation );
    const {rfc,licencia,tiempoEspera,  onInputChange, isFormValid, setFormState } = frmResult;
    const [licenciasList, setLicenciasList] = useState([]);
    const [empresasList, setEmpresasList] = useState([]);

    const optionsPeriodo = [
        { value: '0', label: 'Sin resumen' }, { value: '1', label: '1 Hora' },
        { value: '2', label: '2 Horas' },{ value: '3', label: '3 Horas' },
        { value: '4', label: '4 Horas' },{ value: '6', label: '6 Horas' },
        { value: '7', label: '7 Horas' },
        { value: '8', label: '8 Horas' }, { value: '9', label: '9 Horas' },
        { value: '10', label: '10 Horas' },{ value: '11', label: '11 Horas' },
        { value: '12', label: '12 Horas' },{ value: '13', label: '13 Horas' },
        { value: '14', label: '14 Horas' },{ value: '15', label: '15 Horas' },
        { value: '16', label: '16 Horas' },{ value: '17', label: '17 Horas' },
        { value: '18', label: '18 Horas' },{ value: '19', label: '19 Horas' },
        { value: '20', label: '20 Horas' },{ value: '21', label: '21 Horas' },
        { value: '22', label: '22 Horas' },{ value: '23', label: '23 Horas' },
    ]

    useEffect(()=>{
        setFormState( initialForm );
    } ,[uuid])


    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const onClickGuardar = ()=>{
        if(!isFormValid){
            for (const prop in frmResult.validationResult) {
                if (frmResult.validationResult[prop] !== null) {
                    const alert = {
                        message : frmResult.validationResult[prop],
                        icon:"",
                    }
                    dispatch( setAlertMessage( alert )  );
                    return;
                }
            }
            return;
        }
        const params = {
            ...initialForm,
            licencia,
            rfc,
            tiempoEspera,
        };

       if(uuid){
           dispatch( actualizarCuenta( params )  );
       }else{
           dispatch( crearCuenta( params )  );
       }
    }

    const handleClose = ()=>{
        localStorage.removeItem("accountId");
        navigate('/cuentas-stp',{replace:true} );
    }

    const handleClose2 = ()=>{
        localStorage.removeItem("accountId");
        dispatch( cuentaCancelEdit() );
    }

    return (
        <>
            <Grid container  p={1}>
                <Grid item xs={6} md={1} xl={1}  >
                    <label className={"IGenericLabel"}>Licencia</label>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <select className={ "" } name={"licencia"}  onChange={ onInputChange } >
                        {

                        }
                    </select>
                    <input type={"text"} className={"ITextbox"} name={"licencia"} value={ licencia } onChange={ onInputChange } />
                </Grid>
                <Grid item xs={6} md={1} xl={1} >
                    <label className={"IGenericLabel"} >RFC</label>
                </Grid>
                <Grid item xs={6} md={2} xl={2}>
                    <input type={"text"} className={"ITextbox"} name={"rfc"} value={ rfc } onChange={ onInputChange } />
                </Grid>
                <Grid item xs={6} md={2} xl={2}  >
                    <label className={"IGenericLabel"}>Tiempo de espera <i className="fa-solid fa-circle-info" title={"Tiempo que debe transcurrir para poder consultar una transacció tomando como punto de partida su envio a la TPV"}></i> </label>
                </Grid>
                <Grid item xs={6} md={1} xl={1}>
                    <input type={"text"} className={"ITextbox"} name={"tiempoEspera"}  value={ tiempoEspera } onChange={ onInputChange } />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} md={3} xl={3} mt={2}    >
                    <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ handleClose }>
                        <i className="fa-solid fa-chevron-left"></i> Ir a cuentas
                    </button>
                </Grid>
                <Grid item xs={6} md={3} xl={5} mt={2}    >
                </Grid>
                <Grid item xs={6} md={3} xl={2} mt={2}    >
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={ onClickGuardar }  >
                        <i className="fa-solid fa-circle-check"></i> Aceptar
                    </button>
                </Grid>
                <Grid item xs={6} md={3} xl={2} mt={2} pl={1}  >
                    <button className={"btn-alpha-danger pad-element"} type={"submit"} onClick={ handleClose2 }>
                        <i className="fa-solid fa-circle-xmark"></i> Cancelar
                    </button>
                </Grid>
            </Grid>
        </>
    )



}

export default Detalle;