import * as React from "react";
import PropTypes from "prop-types";

const defaultPaginator = {
    next: null,
    prev: null,
}

import SoftBox from "../../../components/SoftBox";

const Paginador =  ({ cursor = defaultPaginator, nextEvent, prevEvent }) => {

    const onNextClic = () =>{
        const filtro = createObjetLink(cursor.next);
        nextEvent(filtro);
    }

    const onPrevClic = () =>{
        const filtro = createObjetLink(cursor.next);
        prevEvent(filtro);
    }

    const createObjetLink = (url) =>{
        const searchParams = new URLSearchParams(url.split('?')[1]);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    }

    const labelPrev = <td className={"ICellPaginateItem"}><a href={"#"} className={"IBtnPaginate"} onClick={ onPrevClic } > <i className="fa-solid fa-angles-left"></i> <label>Anterior</label> </a> </td>;
    const labelNext = <td className={"ICellPaginateItem"} ><a href={"#"} className={"IBtnPaginate"} onClick={ onNextClic }> <label>Siguiente</label><i className="fa-solid fa-angles-right"></i> </a></td>;

    const paginate = <SoftBox pt={1} pb={1}><table className={"IPaginatorSection"}><tbody><tr>
        {
            cursor.prev !== null && labelPrev
        }
        {
            cursor.next !== null && labelNext
        }
    </tr></tbody></table></SoftBox>;

    return (cursor.prev !== null || cursor.next !== null ) ? paginate:"";
}

Paginador.propTypes = {
    cursor: PropTypes.object.isRequired,
    nextEvent: PropTypes.func,
    prevEvent: PropTypes.func,
}

export default Paginador;